@import "variables";

@import "vendors/antd/antd";

.detail-container {
  padding: 16px;
  border: 1px solid #dedede;
  border-radius: 5px;
  background: white;
  margin-bottom: 2em;

  .row {
    display: flex;
    padding: 0 16px;
  }

  .row .title {
    flex: 1;
    padding: 8px;
    background-color: #e8f4ff;
    border-bottom: 1px solid #dedede;
  }

  .row .value {
    flex: 1.5;
    padding: 8px;
    border-bottom: 1px solid #dedede;
  }
}

.mapMarkerStyle {
  background-color: red;
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  left: -0.5rem;
  top: -0.5rem;
  position: relative;
  border-radius: 1.5rem 1.5rem 0;
  transform: rotate(45deg);
  border: 1px solid #FFFFFF;
}

.mapMarkerStyle::after {
  position: absolute;
  content: '';
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin-left: -4px;
  margin-top: -4px;
  background-color: #fff;
}





