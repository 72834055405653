

// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
@import "~antd/dist/antd.less";

// mapping scss veraibles to less
@dark: #343a40; // $gray-800
@cyan: #01BCD4;
@green: #66BB6A;

// -------- Colors -----------
@primary-color          : @blue-6; // #1890ff
@info-color             : @cyan;
@success-color          : @green;
@processing-color       : @blue-6;
@error-color            : @red-5; // #ff4d4f
@highlight-color        : @red-5;
@warning-color          : @gold-5; // #ffc53d
@normal-color           : #d9d9d9;

//@font-family-no-number  : -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
@font-family-no-number  : -apple-system, BlinkMacSystemFont, "Sarabun", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
@font-family            : @font-family-no-number;
@line-height-base       : 1.714285714285714; // 24px

// Base Scaffolding Variables
// ---

@border-radius-base     : 6px;

// ICONFONT
// @icon-url               : "/vendors/antd/iconfont/iconfont"; // By default it use alicdn.com
@menu-dark-bg: @dark;
@menu-dark-submenu-bg: darken(@dark, 3%);

// Layout
@layout-header-background       : transparent;
