.responsive-table {
  .ant-table-body > table {
    width: 100%;
    margin: 0;
    padding: 0;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .ant-table-thead {
    display: none;

    @media screen and (min-width: 600px) {
      display: table-row-group;
    }
  }
    
  .ant-table-tbody > tr {
    display: block;
    min-width: 320px;

    @media screen and (min-width: 600px) {
      display: table-row;
  
      &:nth-child(even) {
        background: #f2fbff;
      }
    }
  }

  .ant-table-tbody > tr > td {
    display: block;
    text-align: right;
    min-height: 3em;
    border-bottom: 1px solid #f2fbff;
    padding: .5em 1em;

    @media screen and (min-width: 600px) {
      display: table-cell;
      text-align: left;
      border-bottom: none;
      padding: 16px;
    }
  }

  .ant-table-tbody > tr > td:last-child {
    border-bottom: 2px solid #e8e8e8;

    @media screen and (min-width: 600px) {
      border-bottom: none;
    }
  }
  
  .ant-table-tbody > tr > td:before {
    content: attr(class);
    float: left;
    text-transform: uppercase;
    font-weight: bold;

    @media screen and (min-width: 600px) {
      content: "";
      display: none;
    }
  }

}

  